<template>
  <ContentSection :spacing="false" class="mt-2">
    <section class="content-spacing">
      <div class="flex items-center justify-between">
        <TitlePlus :title="`History`" :hidePlus="true" />
        <DateRangePicker
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>

      <div class="summary-card-container">
        <SummaryCard
          title="Total Pass Users"
          :value="getSummaryUsers"
          variant="purple"
        />

        <SummaryCard
          title="Total Pass Earnings"
          :value="getSummaryEarnings"
          variant="blue"
        />
      </div>
    </section>

    <FSTable
      :fst-id="`passDetailsHistory`"
      :headers="tableHeaders"
      :endpoint="
        `/dashboard/invoices/?transaction_type=CREDIT&package=${packageId}`
      "
      :qso="{ prepend: '&', append: '' }"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="`fs-table-row-${itemIndex}`"
          text-fallback-always
        >
          <FSTableRowItem :text="item.id" :truncate="-5" />

          <FSTableRowItem
            :text="item.user.full_name"
            :to="{ name: 'ViewRiderUserProfile', params: { id: item.user.id } }"
            target="_blank"
          />

          <FSTableRowItem :text="item.date" date />

          <FSTableRowItem>
            <div v-if="item.payment_method_details">
              <i
                :class="getPaymentCardIconClass(item.payment_method_details)"
              ></i>
              <span class="ml-1"
                >**{{ item.payment_method_details.number }}</span
              >
            </div>
            <div v-else>{{ item.payment_method }}</div>
          </FSTableRowItem>

          <!-- <FSTableRowItem :text="getInvoiceStatus(item.status)" /> -->
          <FSTableRowItem>
            <x-status
              :text="getPassStatus(item.status)"
              :variant="getPassStatusBadge(item.status)"
              profile="pass"
            />
          </FSTableRowItem>
        </FSTableRow>
      </template>
    </FSTable>
  </ContentSection>
</template>

<script>
import TitlePlus from '@/components/ui/TitlePlus.vue'
import ContentSection from '@/components/layout/ContentSection'
import SummaryCard from '@/components/cards/SummaryCard'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getInvoiceStatus } from '@/utils'
import XStatus from '@/components/badge/XStatus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  // FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'Vehicles',
  components: {
    ContentSection,
    TitlePlus,
    SummaryCard,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    // FSTableFilter,
  },
  props: {
    packageId: {
      required: false,
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_pass_users: '0.00',
          total_pass_earning: '0.00',
        },
      },
      tableHeaders: [
        { text: 'Invoice ID', width: '10%', sort: 'id' },
        { text: 'Rider Name', width: '20%', sort: 'user_id' },
        { text: 'Availed date', width: '20%', sort: 'availed_date' },
        { text: 'Payment method', width: '15%', sort: 'payment' },
        { text: 'Status', width: '10%', sort: 'status' },
      ],
    }
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaryUsers() {
      return this.indexMetaData.summary.total_pass_users
    },
    getSummaryEarnings() {
      const symbol =
        this.indexMetaData?.summary?.currency?.symbol || this.currencySymbol
      return `${symbol} ${this.indexMetaData.summary.total_pass_earning}`
    },
  },
  methods: {
    onApplyFilterDateRange,
    getInvoiceStatus,
    getDiscountAmount(amount) {
      return this.currencySymbol + ' ' + parseFloat(amount).toFixed(2)
    },
    getPaymentCardIconClass(card) {
      console.log('card-details', card)
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
    getPassStatus(status) {
      const statuses = {
        C: 'Complete',
        P: 'Pending',
      }
      return status in statuses ? statuses[status] : '--'
    },
    getPassStatusBadge(status) {
      const badges = {
        C: 'green',
        P: 'orange',
      }
      return status in badges ? badges[status] : 'gray'
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[(_vm.$acl.canNotView('pass'))?_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" You don't have permission to see data. Please Ask your admin to give you permission for this screen. ")]):[(!_vm.pass)?_c('div',{staticClass:"h-screen vld-parent"},[_c('loading',{attrs:{"active":true,"is-full-page":false,"z-index":20}})],1):[_c('div',{staticClass:"flex flex-wrap items-center justify-between w-full px-4 py-3 bg-white rounded"},[_c('div',{staticClass:"flex items-center promotion-header md-max:mt-3"},[_c('span',[_vm._v("Pass Management /")]),_c('h1',[_vm._v("Details (#"+_vm._s(_vm.$truncate(_vm.$route.params.id, -5))+")")])]),_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"\n              flex\n              items-center\n              justify-between\n              px-4\n              py-0\n              font-medium\n              border\n              rounded-full\n              shadow-sm                \n              h-35px\n              text-14px text-oWhite\n              focus:border-border-oWhite                \n              focus:outline-none\n              \n            ",class:[
              _vm.pass.status === 'Active'
                ? 'bg-blue-600 hover:bg-blue-700'
                : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed' ],on:{"click":function($event){return _vm.executeAction('notifyRiders')}}},[_c('div',{staticClass:"flex items-center "},[_c('i',{staticClass:"mr-2 fas fa-paper-plane"}),_c('span',[_vm._v(" Notify Riders ")])])]),_c('ToggleActions',{attrs:{"current-status":_vm.pass.status},on:{"change":_vm.onChangePassStatus}})],1)]),_c('div',{staticClass:"flex flex-col px-4 py-12 mt-2 bg-white rounded md:flex-row"},[_c('pass-card',{staticClass:"w-full md:w-1/3",attrs:{"pass_type":_vm.pass.pass_type,"price":_vm.pass.amount,"unlock_charge":_vm.pass.unlock_charge && _vm.pass.unlock_charge != null ? 1 : 0,"per_minute_charge":1,"reservation_charge":1,"free_minutes_each_ride":_vm.pass.free_minutes_each_ride && _vm.pass.free_minutes_each_ride != null
              ? 1
              : 0,"free_minutes":_vm.pass.free_minutes_each_ride}}),_c('section',{staticClass:"px-2 mt-10 md:grid md:grid-cols-3 md:mt-0 md:gap-5 md:ml-20 md:w-2/3"},_vm._l((_vm.getPassDetails),function(columns,columnsIndex){return _c('div',{key:columnsIndex},_vm._l((columns),function(item,itemIndex){return _c('div',{key:item.text,staticClass:"flex flex-row justify-between mt-5 md:mt-0 md:flex-col md:justify-start",class:("" + (itemIndex % 5 === 0 ? '' : 'md:mt-5'))},[_c('div',{staticClass:"w-1/3 md:w-full view-detail-title "},[_vm._v(" "+_vm._s(item.text)+" ")]),(item.text === 'Assigned Fleet')?_c('div',{staticClass:"w-2/3 md:w-full view-detail-value"},[(item.value)?_c('div',{staticClass:"flex items-center gap-2 flex-wrap"},[_c('RouterLink',{staticClass:"text-blue-600",attrs:{"to":{
                      name: 'ViewFleetProfile',
                      params: { id: item.value.id },
                    },"target":"_blank"}},[_vm._v(_vm._s(item.value.name))])],1):_c('div',{staticClass:"view-detail-value"},[_c('span',[_vm._v("--")])])]):_c('div',{staticClass:"w-2/3 md:w-full view-detail-value"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0)}),0)],1),_c('PassDetailsHistory',{attrs:{"package-id":_vm.id}})],_c('NotifyModal',{key:("notify-" + (_vm.pass.id)),attrs:{"id":("notify-" + (_vm.pass.id)),"isLoading":_vm.isLoading,"title":"Please Select your push medium*","subtitle":"Please select notification channels (SMS, Push, Email)","cancelButtonText":"Cancel","cancelButtonVariant":"secondary","notify-button-text":"Notify","notify-button-variant":"green"},on:{"setQueryString":function (query) { return (_vm.queryString = query); },"notify":_vm.actionNotify,"cancel":function($event){return _vm.executeAction('hideNotifyModal')}}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
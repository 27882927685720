<template>
  <div>
    <div class="relative flex flex-wrap px-10 pass-card">
      <div class="flex items-center w-2/5 left">
        <div class="block">
          <h3 class="block">
            {{
              price == '' || price == undefined
                ? symbol + ' 0.00'
                : symbol + ' ' + price
            }}
          </h3>
          <span class="block mt-2" v-if="pass_type">
            {{ pass_type == 'DD' ? 'Per Day' : '' }}
            {{ pass_type == 'WE' ? 'Per Week' : '' }}
            {{ pass_type == 'MM' ? 'Per Month' : '' }}
            {{ pass_type == 'YY' ? 'Per Year' : '' }}
          </span>
        </div>
      </div>
      <div class="flex items-center w-3/5 right">
        <div class="block check-body">
          <div class="pt-1" v-if="unlock_charge == 1">
            <i class="fas fa-check"></i>
            <span class="ml-2"
              >No Unlock Charge for
              {{ pass_type == 'DD' ? '1 days' : '' }}
              {{ pass_type == 'WE' ? '7 days' : '' }}
              {{ pass_type == 'MM' ? '30 days' : '' }}
              {{ pass_type == 'YY' ? '365 days' : '' }}
            </span>
          </div>
          <div class="pt-1" v-if="free_minutes_each_ride == 1">
            <i class="fas fa-check"></i>
            <span class="ml-2"
              >First {{ free_minutes == '' ? 0 : free_minutes }} minutes
              Free</span
            >
          </div>
          <div class="pt-1">
            <i class="fas fa-check"></i>
            <span class="ml-2">Get Price Protection</span>
          </div>
          <div class="pt-1">
            <i class="fas fa-check"></i>
            <span class="ml-2">Cancel Anytime</span>
          </div>
        </div>
      </div>
      <div class="save-body">
        <div class="flex items-center justify-center w-full">
          <span>20% SAVE</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PromoCard',
  components: {},
  data() {
    return {
      isLoading: false,
      symbol: '$',
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
  },
  props: {
    pass_type: {
      required: false,
    },
    price: {
      required: false,
    },
    unlock_charge: {
      required: false,
    },
    reservation_charge: {
      required: false,
    },
    per_minute_charge: {
      required: false,
    },
    free_minutes_each_ride: {
      required: false,
    },
    free_minutes: {
      required: false,
      default: '',
    },
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
  },
  methods: {
    updateCard: function() {
      console.log(this.type)
      if (this.type == 'RD') this.active_class = 'rd_color'
      else if (this.type == 'FD') this.active_class = 'fd_color'
      else if (this.type == 'FR') this.active_class = 'fr_color'
    },
  },
}
</script>

<style lang="scss" scoped>
.pass-card {
  width: 100%;
  min-height: 205px;
  height: 100%;
  background: #fafc03;
  border-radius: 18px;
  border: 2px dashed #b3b302;
  .left {
    h3 {
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
  }
  .right {
    .check-body {
      padding-top: 10px;
      i {
        color: #00d092;
        font-size: 12px;
      }
      span {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #000000;
      }
    }
  }
}
.save-body {
  background: #ee9200;
  width: 158px;
  height: 45px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 90px;
  display: flex;
  span {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}
.circle {
  width: 79px;
  height: 54px;
  transform: rotate(-195deg);
  border: solid 53px #0dff99;
  position: absolute;
  bottom: -35px;
  right: -15px;
}
</style>
